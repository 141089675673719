<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ServiceUnavailablePage',
  computed: {
    ...mapGetters({ offlineEnd: 'ui/offlineEnd' }),
  },
};
</script>

<template>
  <b-container>
    <b-row align-h='center' align-v='center'>
      <b-col cols=4>
        <h3>PMGateway is down for maintenance</h3>
        <p v-if='offlineEnd'>
          Maintenance is scheduled to be complete by {{ $d(offlineEnd, 'long') }}
        </p>
        <p v-else>
          Please check back in a bit
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>
